import { TInstitutionStructureType } from '@ascd/witsby-components';
import { ILearnerCollectionRoutes } from '@types';

export const ASCD_ID = '63c7c48a3627a748a0cdee36';

export const STRUCTURE_TYPE = {
  SECTION: 'SECTION',
  CHAPTER: 'CHAPTER',
};

export const COURSE_TYPES = {
  TOP_COURSES: 'Top Courses',
  TOP_LEARNING_LISTS: 'Top Learning Lists',
  TOP_LEARNING_ITEMS: 'Top Learning Items',
  TOP_EBOOKS: 'Top Ebooks',
  TOP_WEBINARS: 'Top Webinars',
  TOP_RESOURCES: 'Top Resources',
};
export const DASHBOARD_CARD_TITLE = {
  CLOCK_HOURS: 'Clock Hours',
  AWARDS_EARNED: 'Awards Earned',
  COURSES_COMPLETED: 'Course Completed',
  LEARNING_LISTS_COMPLETED: 'Learning List Completed',
  LEARNING_ITEM_COMPLETED: 'Learning Item Completed',
  STRATEGIES_ADOPTED: 'Strategies Adopted',
  WHOLE_LEARNER: 'Whole Learner',
};

export const DASHBOARD_CARD_TYPES = [
  { cardTitle: COURSE_TYPES.TOP_COURSES },
  { cardTitle: COURSE_TYPES.TOP_LEARNING_LISTS },
  { cardTitle: COURSE_TYPES.TOP_LEARNING_ITEMS },
  { cardTitle: COURSE_TYPES.TOP_EBOOKS },
  { cardTitle: COURSE_TYPES.TOP_RESOURCES },
  { cardTitle: COURSE_TYPES.TOP_WEBINARS },
];

export const MOBILE_NAV_BAR_LIST = [
  {
    icon: 'HOME',
    pageName: 'Home',
    pathName: '/',
  },
  {
    pageName: 'My Learning',
    icon: 'MY_LEARNING',
    pathName: '/my-learning',
  },
  {
    icon: 'NAVBAR_SEARCH',
    pageName: 'Library',
    pathName: '/search',
  },
  // {
  //   icon: 'MY_COMMUNITY',
  //   pageName: 'Community',
  //   pathName: '/community',
  // },
  {
    icon: 'MY_PORTFOLIO',
    pageName: 'Portfolio',
    pathName: '/portfolio',
  },
];

export const UploadItemsTypes = {
  URL: 'URL',
  EMBED: 'EMBED',
  UPLOAD: 'UPLOAD',
};

export const CARD_BUTTON_NAME = {
  ENROLL: 'Enroll',
  START: 'Start',
  RESUME: 'Resume',
  COMPLETED: 'Completed',
  ADD: 'Add',
};

export enum SEARCH_SORT_OPTIONS {
  A_Z = 'A_Z',
  Z_A = 'Z_A',
  LATEST = 'LATEST',
}

export enum LEARNING_LIST_TYPE {
  INSTITUTIONAL_LEARNING_LIST = 'INSTITUTIONAL_LEARNING_LIST',
  USER_LEARNING_LIST = 'USER_LEARNING_LIST',
}

export const SEARCH_SORT_OPTIONS_LABELS = {
  [SEARCH_SORT_OPTIONS.A_Z]: 'A to Z',
  [SEARCH_SORT_OPTIONS.Z_A]: 'Z to A',
  [SEARCH_SORT_OPTIONS.LATEST]: 'Latest',
};

export const SEARCH_STATUS_OPTIONS = {
  ALL: 'All',
  COMPLETED: 'Completed',
  NOT_COMPLETED: 'Not Completed',
};

export const FILTER_TYPE = {
  CATEGORY: 'CATEGORY',
  TAG: 'TAG',
  SUB_CATEGORY: 'SUB-CATEGORY',
};

export enum EBOOK_FILTER_OPTIONS {
  ACTIVE = 'ACTIVE',
  UPCOMING = 'UPCOMING',
  EXPIRED = 'EXPIRED',
}

export enum EBOOK_FILTER_COLOR {
  GREEN = '#C5F3D2',
  PINK = '#D8F33A',
  YELLOW = '#F3EFC5',
}

export const DEFAULT_VIEW_MODE_VALUES = {
  isGridView: false,
  isTableView: false,
  isTileView: false,
};

export const TAGS_NAME = {
  LEARNING_ITEM: 'Learning Item',
  AUTHOR_INTERVIEW: 'Author Interview',
  INSTITUTIONAL_LEARNING_LIST: 'Institutional Learning List',
  USER_LEARNING_LIST: 'Personal Learning List',
  ARTICLE: 'Article',
  BLOG: 'Blog',
  PODCAST: 'Podcast',
  WORKSHOP: 'Workshop',
  WEBINAR: 'Webinar',
  TOOL: 'Tool',
  INTERACTIVE: 'Interactive',
  STUDY_GUIDE: 'Study Guide',
  RESOURCES: 'Resources',
  LEARNING_ITEM_WO_TITLE: 'Learning Item wo/Title',
  LEARNING_ITEM_WITH_TITLE: 'Learning Item w/Title',
  LEARNING_LIST: 'Learning List',
  COURSE: 'Course',
  MY_INSTITUTE: 'My Institution',
  ASCD: 'ASCD',
  STRATEGY: 'Strategy',
  VIDEO: 'Video',
  DOCUMENT: 'Document',
  TEXT: 'Text',
  GRAPHICS: 'Graphics',
  BOOK_SUMMARY: 'Book Summary',
};

export const LEARNING_TYPE_TAGS = [
  TAGS_NAME.AUTHOR_INTERVIEW,
  TAGS_NAME.AUTHOR_INTERVIEW,
  TAGS_NAME.ARTICLE,
  TAGS_NAME.BLOG,
  TAGS_NAME.PODCAST,
  TAGS_NAME.WORKSHOP,
  TAGS_NAME.WEBINAR,
  TAGS_NAME.TOOL,
  TAGS_NAME.INTERACTIVE,
  TAGS_NAME.STUDY_GUIDE,
  TAGS_NAME.RESOURCES,
  TAGS_NAME.VIDEO,
  TAGS_NAME.DOCUMENT,
  TAGS_NAME.TEXT,
  TAGS_NAME.GRAPHICS,
  TAGS_NAME.BOOK_SUMMARY,
];
export const COLLECTION_TYPE_TAGS = [
  TAGS_NAME.INSTITUTIONAL_LEARNING_LIST,
  TAGS_NAME.USER_LEARNING_LIST,
  TAGS_NAME.LEARNING_ITEM_WO_TITLE,
  TAGS_NAME.LEARNING_ITEM,
  TAGS_NAME.COURSE,
  TAGS_NAME.STRATEGY,
];

export const CATEGORY_TYPE = {
  LEARNING_ITEM_TYPES: 'LearningItemTypes',
  GRADE_LEVEL: 'GradeLevel',
  AUDIENCE: 'Audience',
  SUBJECT_AREAS: 'SubjectAreas',
  WHOLE_LEARNER: 'WholeLearner',
  STRATEGY: 'Strategy',
};

export const CATEGORY_TYPE_LIST = [
  CATEGORY_TYPE.LEARNING_ITEM_TYPES,
  CATEGORY_TYPE.GRADE_LEVEL,
  CATEGORY_TYPE.AUDIENCE,
  CATEGORY_TYPE.SUBJECT_AREAS,
  CATEGORY_TYPE.WHOLE_LEARNER,
  CATEGORY_TYPE.STRATEGY,
];

export const STRATEGY_TAGS = {
  INSTRUCTION: 'instruction',
  SOCIAL_EMOTIONAL: 'social_emotional_learning',
  CLASSROOM_MANAGEMENT: 'classroom_management',
  EDUCATOR_WELL_BEING: 'educator_well-Being',
};
export const USER_ROLES = {
  ADMIN: 'admin',
  LEARNER: 'learner',
  AUTHOR: 'author',
  MANAGER: 'leader',
  INSTITUTIONAL_ADMIN: 'institutionalAdmin',
  ASCD_ADMIN: 'ascdAdmin',
};

export const SALESFORCE_USER_ROLES = {
  ADMIN: 'Admin',
  LEARNER: 'Learner',
  AUTHOR: 'Author',
  MANAGER: 'Leader',
  INSTITUTIONAL_ADMIN: 'Institutional Admin',
  ASCD_ADMIN: 'ASCD Admin',
  ASCD: 'ASCD',
};

export enum BUTTON_GROUP {
  DASHBOARD = 'Dashboard',
  GROUP_PROGRESS = 'Assignment Progress',
  ASSIGNMENT = 'Manage Assignments',
  MANAGE_GROUPS = 'Manage Groups',
  MANAGE_USERS = 'User Management',
  AUTHORING = 'Authoring',
  ADMIN_SETTINGS = 'Admin Settings',
}

export enum HOME_SECTION_NAME {
  LATEST_RELEASES = 'LATEST_RELEASES',
  AVAILABLE_COURSES = 'AVAILABLE_COURSES',
  EBOOKS = 'EBOOKS',
  LIVE_EVENTS = 'LIVE_EVENTS',
  AVAILABLE_LEARNING_LIST = 'Learning List',
  AVAILABLE_RECORDINGS = 'Available Recordings',
  RESOURCES_CARD = 'Resources',
  EL_MAGAZINE = 'EL_MAGAZINE',
  AVAILABLE_STRATEGIES = 'Available Strategies',
}

export enum FEATURED_EBOOKS {
  TITLE = 'title',
  ISBN = 'ISBN',
  E_BOOK_DESCRIPTION = 'description',
  LOCK_LIZARD_BOOK_ID = 'LockLizardBookId',
  TYPE = 'type',
  ACTIVE_DATE = 'activeDate',
  ASCD_BOOK_ID = 'ascdBookId',
  ASSIGN_TAGS = 'assignTags',
  CREATED_AT = 'createdAt',
  EXPIRY_DATE = 'expiryDate',
  IMAGE_NAME = 'imageName',
  STATUS = 'status',
  SLUG = 'slug',
  UPDATE_AT = 'updatedAt',
  RELATIVE_SOURCE_ID = 'relativeSourceId',
  TYPE_NAME = '__typename',
}

export const STRUCTURE_TYPES: TInstitutionStructureType[] = [
  'COUNTRY',
  'STATE',
  'DISTRICT',
  // 'OTHER',
  'SCHOOL',
];

export const MANAGE_GROUP_OPTIONS = {
  ASSIGN_USERS: 'Select Group Participants',
  ASSIGN_MANAGER: 'Select Group Leader(s)',
  EDIT_GROUP_NAME: 'Edit Group Name',
  DELETE_GROUP_NAME: 'Delete Group',
};

export const MANAGE_GROUP_TYPE = {
  MANAGE_PARTICIPANTS: 'MANAGE_PARTICIPANTS',
  MANAGE_MANAGERS: 'MANAGE_LEADERS',
};

export const MANAGE_GROUP_BUTTON = {
  EDIT: 'edit',
  ASSIGN: 'Assign',
  SAVE: 'Save',
  CREATE: 'create',
  UPDATE: 'Update',
};

export const PROFILE_FORM_TYPE = {
  UPDATE: 'update',
  CREATE: 'create',
};

export const ADMIN_ROUTES = {
  ADMIN: '/admin',
  SETTINGS: '/admin/settings',
  AUTHORING: '/admin/authoring',
  AUTHORING_DEFAULT: '/admin/authoring?tab=courses',
  MANAGE_USERS: '/admin/manage-users',
  MANAGE_GROUPS: '/admin/manage-groups',
  ASSIGNMENT: '/admin/assignment',
  GROUP_PROGRESS: '/admin/assignment-progress',
};

export const LEARNER_COLLECTION_ROUTES: ILearnerCollectionRoutes = {
  COURSE: '/course',
  STRATEGY: '/strategy',
  USER_LEARNING_LIST: '/learning-list',
  INSTITUTIONAL_LEARNING_LIST: '/learning-list',
};

export const OKTA_ACTIVE_USER_STATUS = [
  'ACTIVE',
  'PASSWORD_EXPIRED',
  'RECOVERY',
  'LOCKED_OUT',
  'PROVISIONED',
];

export const NAV_BAR_LIST = [
  {
    icon: 'HOME',
    pageName: 'Home',
    pathName: '/',
  },
  {
    icon: 'NAVBAR_SEARCH',
    pageName: 'Library',
    pathName: '/search',
  },
  {
    pageName: 'My Learning',
    icon: 'MY_LEARNING',
    pathName: '/my-learning',
  },
  // {
  //   icon: 'MY_COMMUNITY',
  //   pageName: 'Community',
  //   pathName: '/community',
  // },
  {
    icon: 'MY_PORTFOLIO',
    pageName: 'Portfolio',
    pathName: '/portfolio',
  },
  {
    icon: 'ADMIN',
    pageName: 'Admin',
    pathName: ADMIN_ROUTES.ADMIN,
  },
  {
    icon: 'HELP',
    pageName: 'Support',
    pathName: process.env.NEXT_PUBLIC_WITSBY_PRO_SUPPORT_URL,
    openInNewTab: true,
  },
];

export const SOURCE = {
  OKTA: 'okta',
  SALESFORCE: 'sf',
};

export const WITSBY_USER_EMAIL_COOKIE_NAME = 'WitsbyUserEmail';
