import { IncomingMessage, ServerResponse } from 'http';
import { useMemo } from 'react';
import {
  HttpLink,
  ApolloLink,
  FetchResult,
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition, Observable } from '@apollo/client/utilities';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import get from 'lodash/get';
import omitDeep from 'omit-deep-lodash';
import { WITSBY_USER_EMAIL_COOKIE_NAME } from '@constants';
// import { handleGraphqlError } from '@utils';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

export type ResolverContext = {
  req?: IncomingMessage;
  res?: ServerResponse;
};

const logOut = () => {
  deleteCookie('source');
  deleteCookie('witsby-role');
  deleteCookie('accessToken');
  deleteCookie('refreshToken');
  deleteCookie('magazineAccessed');
  deleteCookie(WITSBY_USER_EMAIL_COOKIE_NAME);
  window.location.href = `${process.env.SAML_URL}/auth/logout`;
};

// Request a refresh token to then stores and returns the accessToken.
const getNewRefreshToken = async () => {
  try {
    const refreshToken = getCookie('refreshToken');
    const response = await fetch(`${process.env.SAML_URL}/newToken`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    });
    const data = await response.json();
    const newAccessToken = data?.accessToken;
    const newRefreshToken = data?.refreshToken;
    if (newAccessToken && newRefreshToken) {
      setCookie('accessToken', newAccessToken);
      setCookie('refreshToken', newRefreshToken);
      return { accessToken: newAccessToken, refreshToken: newAccessToken };
    }

    return { accessToken: '', refreshToken: '' };
  } catch (error) {
    return { accessToken: '', refreshToken: '' };
  }
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    if (graphQLErrors?.some((v) => v?.extensions?.code === 'UNAUTHENTICATED')) {
      // window.location.href = '/';
      return new Observable<FetchResult<Record<string, unknown>>>((observer) => {
        (async () => {
          try {
            const oktaAccessToken = get(
              localStorage.getItem('okta-token-storage')
                ? JSON.parse(localStorage.getItem('okta-token-storage') || '')
                : '',
              'accessToken.accessToken',
            );
            if (!oktaAccessToken) {
              const accessToken = await getNewRefreshToken();
              if (!accessToken || !accessToken?.accessToken || !accessToken?.refreshToken) {
                logOut();
              }
            }

            // Retry the failed request
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };

            forward(operation).subscribe(subscriber);
          } catch (error) {
            observer.error(error);
          }
        })();
      });
    }
    const def = getMainDefinition(operation.query);
    if (def && def?.operation === 'query') {
      // handleGraphqlError(errors);
    }
    graphQLErrors.map((graphqlError) => graphqlError);
  }
  if (networkError) {
    console.error('networkError', networkError);
  }
  return forward(operation);
  // prepared for logging
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {},
    },
  },
});

const contentfulCache = new InMemoryCache({});

function createApolloClient() {
  const authLink = setContext((_, { headers }) => {
    const oktaAccessToken = get(
      localStorage.getItem('okta-token-storage')
        ? JSON.parse(localStorage.getItem('okta-token-storage') || '')
        : '',
      'accessToken.accessToken',
    );
    const accessToken = getCookie('accessToken');
    const userinfo = localStorage.getItem('header-info') || '';
    return {
      headers: {
        ...headers,
        userinfo,
        authorization:
          accessToken || oktaAccessToken ? `Bearer ${accessToken || oktaAccessToken}` : '',
      },
    };
  });

  const uri = process.env.SERVER_URL;

  const httpLink = new HttpLink({
    uri,
    credentials: 'same-origin',
  });

  const cleanTypenameLink = new ApolloLink((operation, forward) => {
    const def = getMainDefinition(operation.query);
    if (def && def.operation === 'mutation') {
      // eslint-disable-next-line no-param-reassign
      operation.variables = omitDeep(operation.variables, ['__typename']);
    }
    return forward ? forward(operation) : null;
  });

  return new ApolloClient({
    cache,
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([cleanTypenameLink, errorLink, authLink.concat(httpLink)]),
  });
}

export function initializeApollo(
  initialState: NormalizedCacheObject | null,
): ApolloClient<NormalizedCacheObject> {
  const apolloClientInitialized = apolloClient ?? createApolloClient();

  if (initialState) {
    const existingCache = apolloClientInitialized.extract();

    apolloClientInitialized.cache.restore({
      ...existingCache,
      ...initialState,
    });
  }

  if (typeof window === 'undefined') return apolloClientInitialized;

  if (!apolloClient) apolloClient = apolloClientInitialized;

  return apolloClientInitialized;
}

export function useApollo(
  initialState: NormalizedCacheObject | null,
): ApolloClient<NormalizedCacheObject> {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}

export const contentfulDirectClient = new ApolloClient({
  connectToDevTools: true,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      }
      if (networkError) console.error(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}/environments/${process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT}`,
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${
          process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW
            ? process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_ACCESS_TOKEN
            : process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN
        }`,
      },
    }),
  ]),
  cache: contentfulCache,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
  },
});
